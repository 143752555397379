import { createStandaloneToast } from "@chakra-ui/react"
import { Text } from "@chakra-ui/react";

function Toast(message, type) {
  const toast = createStandaloneToast()
  return toast({
      title: "eHarmony",
      position: "top",
      description: <Text fontWeight="500">{message}</Text>,
      status: type,
      duration: 3000,
      isClosable: false,
    })
}

export default Toast;